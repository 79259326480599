@tailwind components;

:root {
    --swiper-navigation-color: #ffffff;
}

@layer components {
    .everyone-section {
        .swiper-slide-active {
            .item-desc {
                @apply translate-y-0;
            }
            .item-bg {
                @apply bg-gradient-to-t from-black to-transparent;
            }
        }
    }

    .why-eplay {
        .swiper-slide-active {
            @apply opacity-100;

            .item-bg {
                @apply md:bg-gradient-to-t md:from-main/30 md:to-darker;
            }
        }
    }

    .latest-elpay-section {
        .swiper-slide-active {
            @apply opacity-100;
        }
    }
}
